import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'nprogress/nprogress.css';
import App from './components/App';
import './i18n';
import './main.less';

// get the container
const container = document.getElementById('root');

// get app data sent by the back
Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DNS,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
        }),
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    environment: import.meta.env.MODE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: import.meta.env.DEV ? 0 : 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/staging\.iaudit\.fr/, /^https:\/\/app\.iaudit\.fr/],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: import.meta.env.DEV ? 0 : 1.0,
});

let appElement = <App />;

// Error Component
const ErrorComponent = ({ errorMessage }) => {
    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Oops! Something went wrong.</h2>
            <p style={styles.message}>{errorMessage || 'An unexpected error occurred.'}</p>
            <button style={styles.button} onClick={() => window.location.reload()}>
                Try Again
            </button>
        </div>
    );
};

// Styles
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#dbd7d8',
        color: '#721c24',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
    title: {
        margin: '0',
        fontSize: '24px',
    },
    message: {
        fontSize: '16px',
        margin: '10px 0',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#c82333',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

if (!import.meta.env.DEV) {
    appElement = <Sentry.ErrorBoundary fallback={ErrorComponent}>{appElement}</Sentry.ErrorBoundary>;
}
ReactDOM.createRoot(container).render(appElement);
