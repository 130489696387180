import { format as dateFormat } from 'date-fns';
// Import the 'format' function from 'date-fns' library and rename it to 'dateFormat' for local usage.

import i18n from 'i18next';
// Import the i18next internationalization library.

import detector from 'i18next-browser-languagedetector';
// Import a language detector that automatically detects the user's browser language.

import Fetch from 'i18next-fetch-backend';
// Import a backend that fetches translations via HTTP.

import { initReactI18next } from 'react-i18next';
// Import the 'react-i18next' plugin to integrate i18n with React.

const config = i18n
    .use(Fetch)
    .use(detector)
    .use(initReactI18next)
    .init({
        backend: {
            requestOptions: {
                mode: 'cors',
                credentials: 'same-origin',
                cache: 'no-store',
            },
        },
        defaultNS: 'common',
        fallbackLng: 'en',
        load: 'languageOnly',
        debug: import.meta.env.DEV,
        returnEmptyString: false,
        interpolation: {
            escapeValue: false,
            format: (value, format) => {
                if (value instanceof Date) {
                    return dateFormat(value, format);
                }
                return value;
            },
        },
    });

export default config;
