import { has } from 'lodash';
import { Route, Routes as Switch } from 'react-router-dom';
import AppLoader from './AppLoader';
import { useSession } from './contexts/SessionContext';
import Join from './routes/Join';
import Login from './routes/Login';
import ResetPassword from './routes/ResetPassword';

const Routes = () => {
    const session = useSession();

    if (!has(session, 'user')) {
        return (
            <Switch>
                <Route Component={ResetPassword} path="/resetPassword" exact />
                <Route Component={ResetPassword} path="/resetPassword/:token" />
                <Route Component={Join} path="/join/:token" />
                <Route Component={Login} path="/" />
            </Switch>
        );
    }

    return <AppLoader session={session} />;
};

export default Routes;
